<template>
  <div>
    <div class="c">
      <h3>Zhang Jifu</h3>
      <ul>
        <li>
          <a href="https://zhangjifu.cn" target="_blank" rel="noopener">Cn</a>
        </li>
        <li>
          <a href="https://zhangjifu.com" target="_blank" rel="noopener">Com</a>
        </li>
        <li>
          <a href="weixin://" target="_blank" rel="noopener">WeChat</a>
        </li>
        <li>
          <a href="alipays://" target="_blank" rel="noopener">Alipay</a>
        </li>
      </ul>

      <h3>Jifu Zhang</h3>
      <ul>
        <li>
          <a href="https://jifuzhang.cn" target="_blank" rel="noopener">Cn</a>
        </li>
        <li>
          <a href="https://jifuzhang.com" target="_blank" rel="noopener">Com</a>
        </li>
        <li>
          <a href="taobao://s.taobao.com" target="_blank" rel="noopener">Taobao</a>
        </li>
        <li>
          <a href="alipayqr://platformapi/startapp?saId=10000007" target="_blank" rel="noopener">Qr</a>
        </li>
      </ul>

      <h3>Geeph Zhang</h3>
      <ul>
        <li>
          <a href="https://geeph.tech" target="_blank" rel="noopener">Tech</a>
        </li>
        <li>
          <a href="https://geeph.me" target="_blank" rel="noopener">Me</a>
        </li>
        <li>
          <a href="https://geephzhang.com" target="_blank" rel="noopener">Com</a>
        </li>
      </ul>
    </div>

    <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener" class="b">{{ msg }}</a>
  </div>
</template>

<script>
import screenfull from "screenfull";
export default {
  name: "mind-less",
  props: {
    msg: String,
  },
  mounted() {
    screenfull.toggle();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b {
  color: sandybrown;
  font-size: xx-small;
}

.c {
  margin: 40px 0;
}

h3 {
  color: antiquewhite;
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
  font-size: x-small;
}
</style>
